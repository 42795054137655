export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'id',
    label: 'field.createdDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
      {
    key: 'companyName',
    label: 'field.companyName',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'name',
    sortable: true,
    sortField: 'name',
    label: 'field.fullName',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'phoneNumber',
    label: 'field.phone',
  },
  {
    key: 'province',
    label: 'field.province',
  },
  {
    key: 'status',
    label: 'field.status',
  },
  {
    key: 'lastActivityAt',
    sortable: true,
    sortField: 'lastActivityAt',
    label: 'field.lastActiveDate',
  },
  {
    key: 'ipaddress',
    label: 'field.ipAddress',
  },

  {
    key: 'createdBy',
    label: 'field.bdPic',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'updatedAt',
    sortable: true,
    sortField: 'updatedAt',
    label: 'field.lastUpdated',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'actions',
    label: 'field.action',
  },
];
