<template>
  <b-modal
    cancel-variant="outline-secondary"
    :cancel-title="$t('button.close')"
    centered
    hide-footer
    size="lg"
    v-model="isShow"
  >
    <validation-observer ref="remarkForm" #default="{ invalid }">
      <!-- form -->
      <n-form-confirmation
        ref="formConfirmation"
        key="remarkForm"
        :form="$refs.remarkForm"
        :disabled="invalid"
        @submit="submit"
      >
        <n-input :fields="formInput" v-model="data"> </n-input>
        <b-row>
          <b-col cols="12" class="text-right">
            <n-button-loading
              type="submit"
              variant="primary"
              :loading="loading"
            >
              {{ $t("button.submit") }}
            </n-button-loading>
          </b-col>
        </b-row>
      </n-form-confirmation>
    </validation-observer>
    <hr />
    <n-table
      ref="table"
      :fields="fields"
      :items="items"
      :busy="loading"
      :current-page="query.page"
      :per-page="query.limit"
      :total="total"
      :resource="resource"
      :route="route"
    >
      <template #cell(status)="data">
        <span v-if="data.item.lastActivityAt">
          <b-badge pill variant="success" badge-glow v-if="data.item.isEnable">
            {{ $t(`field.active`) }}
          </b-badge>
          <b-badge pill variant="danger" badge-glow v-else>
            {{ $t(`field.inactive`) }}
          </b-badge>
        </span>
        <b-badge pill variant="info" badge-glow v-else>
          {{ $t(`field.notYetRegister`) }}
        </b-badge>
      </template>
    </n-table>
    <n-pagination
      ref="pagination"
      :total="total"
      :per-page="query.limit"
      :page="query.page"
      @change="list"
    ></n-pagination>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol, BBadge } from "bootstrap-vue";
import FormInput from "./formInput";
import NInput from "@/components/NInput";
import Repository from "@/repositories/RepositoryFactory";
import NFormConfirmation from "@/components/NFormConfirmation";
import NButtonLoading from "@/components/NButtonLoading";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import NPagination from "@/components/NPagination";

const DealerRemarkRepository = Repository.get("dealerRemark");

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BBadge,

    NFormConfirmation,
    NInput,
    NButtonLoading,
    NTable,
    NPagination,
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      isShow: false,
      loading: false,
      items: [],
      total: 0,
      query: {
        page: 1,
        limit: 10,
      },
      data: {
        userId: 1,
        note: null,
      },
      id: null,
    };
  },
  methods: {
    show(item) {
      this.id = item.id;
      this.data = {
        userId: item.id,
        note: null,
      };
      this.items = [];
      this.total = 0;
      this.query.page = 1;
      this.getData();

      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
    list(page = 1) {
      this.query.page = page;
      this.getData();
    },
    getData() {
      if (!this.id) {
        return;
      }

      this.loading = true;
      const params = {
        ...this.query,
        searchFields: [],
        query: [
          {
            searchby: "user_id",
            searchoperator: "=",
            search: this.id,
          },
        ],
      };
      DealerRemarkRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    submit() {
      this.$refs.remarkForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          DealerRemarkRepository.create(this.data)
            .then((response) => {
              this.data.note = null;
              this.$nextTick(() => {
                this.$refs.remarkForm.reset();
              });
              this.getData();
            })
            .catch((error) => {
              if (error.response?.status == 422) {
                this.$refs.remarkForm.setErrors(error.response?.data?.errors);
              }
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
  },
  setup() {
    const fields = [...TableFields];
    const formInput = [...FormInput];
    const resource = "dealer";
    const route = "dealer";

    return {
      fields,
      formInput,
      resource,
      route,
    };
  },
};
</script>